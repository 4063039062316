import React, { useCallback, useMemo } from 'react';
import { stringToExtraShortDate } from 'app/utils/stringToFormatDate';
import { Highlight } from 'react-instantsearch-dom';
import { useRouter } from 'next/router';

import { prospectLogoUrl } from 'app/utils/prospect';
import { Analytics, EventTypes } from 'app/utils/analytics';

const SearchItem = ({ hit, handleClose }) => {
  const router = useRouter();

  const highlighNote = useMemo(() => {
    const index = hit._highlightResult?.notes?.findIndex(
      (note) => note.matchLevel !== 'none',
    );
    if (index !== -1) {
      return <Highlight attribute={`notes[${index}]`} hit={hit} tagName="b" />;
    }
  }, [hit]);

  const handleOnClick = useCallback(() => {
    Analytics.track(EventTypes.searchItemSelected);
    router.push(`/meetings/${hit.slug}`);
    handleClose();
  }, [hit, handleClose]);

  return (
    <div
      className="w-full hover:bg-gray-50 cursor-pointer"
      onClick={handleOnClick}
    >
      <div className="flex justify-between py-3 px-2 items-center">
        <div className="flex items-center">
          <img
            className="flex-0 h-8 w-8 rounded-full"
            src={prospectLogoUrl(hit.prospectLogoUrl)}
          />
          <div className="font-normal px-4 text-sm text-gray-800">
            <Highlight attribute="meetingName" hit={hit} tagName="b" />
          </div>
          <div className="text-gray-400 text-sm">{highlighNote}</div>
        </div>
        <div className="text-gray-400 text-sm">
          {stringToExtraShortDate(hit.meetingStartsAt)}
        </div>
      </div>
    </div>
  );
};

export default SearchItem;
