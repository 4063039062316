import React, { useCallback, useEffect, useState } from 'react';
import Head from 'next/head';
import { useSession } from 'next-auth/client';
import _ from 'lodash';
import * as Sentry from '@sentry/browser';

import { Analytics, EventTypes } from 'app/utils/analytics';

import Header from 'app/components/modules/Header';
import Search from 'app/components/modules/Search';
import Footer from 'app/components/modules/Footer';
import { gql, useMutation, useQuery } from '@apollo/client';
import ToastMessage from 'app/utils/toast';
import { DefaultSession } from 'next-auth';

export interface CustomSession extends Omit<DefaultSession, 'user'> {
  user?: {
    name?: string | null;
    email?: string | null;
    image?: string | null;
    uid?: unknown;
  };
}

interface LayoutProps {
  children: any;
}

const GET_REMAINING = gql`
  query GetRemaining {
    remaining
  }
`;

const CREATE_SEARCH_HISTORY = gql`
  mutation CreateSearchHistory($searchCount: Float!) {
    createSearchHistory(searchCount: $searchCount) {
      id
    }
  }
`;

const AuthorizedLayout = (props: LayoutProps) => {
  const [session] = useSession();
  const [textSearch, setTextSearch] = useState('');
  const [searchCount, setSearchCount] = useState(0);
  const [remainingSearches, setRemainingSearches] = useState<number>();
  const [openSearch, setOpenSearch] = useState(false);

  const { error: errorRemaining, data: dataRemaining } =
    useQuery(GET_REMAINING);
  const [createSearchHistory, { error: errorCreate, data: dataCreate }] =
    useMutation(CREATE_SEARCH_HISTORY);

  useEffect(() => {
    if (!!errorCreate) {
      ToastMessage({
        type: 'error',
        message: 'Could not create the search history',
        error: errorCreate,
      });
    }
    if (!!dataCreate) {
      setSearchCount(0);
    }
  }, [dataCreate, errorCreate]);

  useEffect(() => {
    if (!!errorRemaining) {
      ToastMessage({
        type: 'error',
        message: 'Could not get the remaining search',
        error: errorRemaining,
      });
    }
    if (!!dataRemaining) {
      setRemainingSearches(dataRemaining.remaining);
    }
  }, [dataRemaining, errorRemaining]);

  const handleSetSearchCount = useCallback((newSearchCount) => {
    setSearchCount(newSearchCount);
    debouncedUpdateMeeting(newSearchCount);
  }, []);

  const sendSearchCount = useCallback(async (searchCount) => {
    createSearchHistory({ variables: { searchCount } });
  }, []);

  const debouncedUpdateMeeting = useCallback(
    _.debounce((searchCount) => sendSearchCount(searchCount), 3000),
    [sendSearchCount],
  );

  const handleClose = useCallback(() => {
    Analytics.track(EventTypes.searchClosed);
    setTextSearch('');
    setOpenSearch(false);
  }, []);

  useEffect(() => {
    if (session?.user) {
      Sentry.setUser(session.user);
      const { user }: CustomSession = session;
      Analytics.identify(+user.uid);
      Analytics.people.set({
        $name: user.name,
        $email: user.email,
        plan: 'personal',
      });
    }
  }, [session]);

  return (
    <div className="min-h-screen flex flex-col justify-between bg-white">
      <div>
        <Head>
          <title>Dealnotes</title>
          <link rel="stylesheet" href="https://rsms.me/inter/inter.css"></link>
          <link rel="icon" href="/favicon.ico" />
        </Head>
        <Header
          textSearch={textSearch}
          setTextSearch={setTextSearch}
          setOpenSearch={setOpenSearch}
        />
        <div className="py-10">
          <main>
            <div className="max-w-7xl mx-auto px-6">
              {!openSearch && props.children}
              {!!openSearch && (
                <Search
                  textSearch={textSearch}
                  setTextSearch={setTextSearch}
                  handleClose={handleClose}
                  searchCount={searchCount}
                  setSearchCount={handleSetSearchCount}
                  remainingSearches={remainingSearches}
                  setRemainingSearches={setRemainingSearches}
                />
              )}
            </div>
          </main>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AuthorizedLayout;
