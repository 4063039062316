import MixpanelBrowser from 'mixpanel-browser';
import getConfig from 'next/config';
// import MixpanelServer from 'mixpanel';

const { publicRuntimeConfig } = getConfig();

MixpanelBrowser.init(publicRuntimeConfig.mixPanelToken);

export enum EventTypes {
  pageView = 'page_view',

  signOut = 'sign_out',
  signIn = 'sign_in',

  meetingCardViewNotesCLick = 'meeting_card:view_note_click',
  meetingCardJoinMeetingCLick = 'meeting_card:join_meeting_click',

  meetingTemplateSelected = 'meeting_page:template_selected',
  meetingTemplateCreated = 'meeting_page:template_created',
  meetingTemplateDeleted = 'meeting_page:template_deleted',
  meetingPrevMeetingSelected = 'meeting_page:prev_meeting_selected',
  meetingNewMeetingClicked = 'meeting_page:new_meeting_clicked',
  meetingArchiveProspect = 'meeting_page:prospect_archive_clicked',
  meetingUnarchiveProspect = 'meeting_page:prospect_unarchive_clicked',
  meetingJoinMeetingClicked = 'meeting_page:join_meeting_clicked',

  navBarNewMeetingClicked = 'nav_bar:new_meeting_clicked',
  navBarSearchClicked = 'nav_bar:search_clicked',

  searchItemSelected = 'search:item_selected',
  searchClosed = 'search:closed',

  actionItemToggled = 'action_items:toggled',
  actionItemDueDatePickerOpened = 'action_items:due_date_picker_opened',

  onboardingNextClicked = 'onboarding:next_clicked',
  onboardingFinishClicked = 'onboarding:finish_clicked',
  onboardingSkipClicked = 'onboarding:skip_clicked',
}

export const AnalyticsFactory = (provider: any) => {
  return {
    identify: (id: string | number) => {
      provider.identify(id);
    },
    alias: (id: string) => {
      provider.alias(id);
    },
    track: (name: string, props: any = {}) => {
      provider.track(name, props);
    },
    people: {
      set: (props: any) => {
        provider.people.set(props);
      },
    },
  };
};

export const Analytics = AnalyticsFactory(MixpanelBrowser);

export const getInitialProps = () => {};
