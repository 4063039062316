import React, { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import { Placement } from '@popperjs/core';
import { classNames } from 'app/utils/classNames';

interface PopoverProps {
  elementRef: any;
  children: JSX.Element;
  popoverShow: boolean;
  placement?: Placement;
  className?: string;
  ignorePopoverStyle?: boolean;
}

const Popover = ({
  elementRef,
  children,
  popoverShow,
  placement = 'bottom-start',
  className,
  ignorePopoverStyle,
}: PopoverProps) => {
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes, update } = usePopper(elementRef, popperElement, {
    placement,
  });
  useEffect(() => {
    if (popoverShow) {
      update();
    }
  }, [popoverShow]);
  return (
    <>
      {!!elementRef && (
        <div
          ref={setPopperElement}
          className={classNames(
            popoverShow ? '' : 'hidden ',
            'bg-white shadow-md border border-gray-200',
            'py-2 block z-50 font-normal leading-normal text-sM no-underline break-words rounded',
            className,
          )}
          style={!ignorePopoverStyle ? styles.popper : {}}
          {...attributes.popper}
        >
          {children}
        </div>
      )}
    </>
  );
};

export default Popover;
