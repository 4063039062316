import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { XIcon } from '@heroicons/react/outline';
import TextareaAutosize from 'react-textarea-autosize';
import Button from './Button';
import Popover from './Popover';
import { AttendeeInterface } from 'app/utils/google';
import { useMutation } from '@apollo/client';
import { INVITE_USER } from 'app/api/mutations';
import ToastMessage from 'app/utils/toast';
import { useForm } from 'react-hook-form';
import { classNames } from 'app/utils/classNames';

interface InviteUserProps {
  referenceElement: any;
  attendee?: AttendeeInterface;
  popoverShow: boolean;
  setPopoverShow: Dispatch<any>;
}

const InviteUser = ({
  referenceElement,
  attendee,
  popoverShow,
  setPopoverShow,
}: InviteUserProps) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [
    inviteUser,
    {
      data: dataInviteUser,
      error: errorInviteUser,
      loading: loadingInviteUser,
    },
  ] = useMutation(INVITE_USER);

  useEffect(() => {
    if (!!errorInviteUser) {
      ToastMessage({
        type: 'error',
        message: 'Could not invite the user',
        error: errorInviteUser,
      });
      reset();
      setPopoverShow(false);
    }
    if (!!dataInviteUser) {
      if (dataInviteUser.inviteUser) {
        ToastMessage({
          type: 'success',
          message: 'Invitation was successfully sent',
        });
      } else {
        ToastMessage({
          type: 'error',
          message: 'Could not invite the user',
        });
      }
      reset();
      setPopoverShow(false);
    }
  }, [dataInviteUser, errorInviteUser]);

  const onSubmit = handleSubmit(async ({ email, message }) => {
    inviteUser({
      variables: { data: { email: attendee?.email ?? email, message } },
    });
  });

  const handleClose = useCallback(() => {
    reset();
    setPopoverShow(false);
  }, []);

  return (
    <Popover popoverShow={popoverShow} elementRef={referenceElement}>
      <div className="py-2 px-4">
        <div className="flex flex-row mb-2 justify-between">
          <div className="font-medium">
            {!!attendee && (
              <p>Invite {attendee.fullName || attendee.email} to Dealnotes</p>
            )}
            {!attendee && <p>Invite a teammate</p>}
          </div>
          <button
            onClick={() => handleClose()}
            className="focus:outline-none focus:ring-0 rounded-md hover:bg-gray-100"
            data-testid="close-popover"
          >
            <XIcon
              className="block h-6 w-6 text-gray-300 hover:text-gray-400"
              aria-hidden="true"
            />
          </button>
        </div>
        <form className="py-3" onSubmit={onSubmit}>
          {!!attendee && (
            <TextareaAutosize
              {...register('message')}
              placeholder="Custom message (optional)"
              className="w-80 min-w-full text-sm border-gray-300 rounded-md focus:ring-0 resize-none p-2"
              minRows={2}
              maxLength={1000}
              data-testid={`send-invite-custom-message`}
            />
          )}
          {!attendee && (
            <div className="items-center">
              <div className="col-span-4">
                <input
                  {...register('email', {
                    required: 'email is required',
                    pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                    value: attendee?.email ?? '',
                    validate: (value: string) =>
                      !!value.trim() || 'email is required',
                  })}
                  type="text"
                  name="email"
                  id="email"
                  data-testid="send-invite-email"
                  autoComplete="cc-name"
                  placeholder="Your teammate's email"
                  maxLength={100}
                  className={classNames(
                    errors.email
                      ? 'text-red-900 border-red-300'
                      : 'border-gray-300 text-gray-400',
                    'block w-80 min-w-full border rounded-md shadow-sm py-2 px-3 text-sm',
                  )}
                />
                {!!errors.email && (
                  <p className="mt-1 text-xs text-red-500" id="email-error">
                    {errors.email.type === 'pattern' && 'enter a valid email'}
                  </p>
                )}
              </div>
            </div>
          )}
        </form>
        <div>
          <Button
            type="primary"
            className="inline"
            data-testid="invite-attendee"
            disabled={loadingInviteUser}
            onClick={onSubmit}
          >
            Send Invite
          </Button>
        </div>
      </div>
    </Popover>
  );
};

export default InviteUser;
