import React, { Fragment, useCallback, useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { BellIcon, MenuIcon, XIcon, GiftIcon } from '@heroicons/react/outline';
import { DocumentAddIcon } from '@heroicons/react/solid';
import { useRouter } from 'next/router';
import SearchInput from '../elements/SearchInput';
import Button from '../elements/Button';
import { useSession } from 'next-auth/client';
import Avatar from 'boring-avatars';
import { Analytics, EventTypes } from 'app/utils/analytics';
import InviteUser from '../elements/InviteUser';

const navigation = [
  { name: 'Dashboard', href: '/' },
  {
    name: 'Prospects',
    href: '/prospects',
    children: [/\/meeting(\/.*)?/g, /\/prospects(\/.*)?/g],
  },
];
const userNavigation = [
  { name: 'Your Profile', href: '/account', onClick: null },
  {
    name: 'Sign out',
    href: '/api/auth/signout',
    onClick: () => {
      Analytics.track(EventTypes.signOut, {});
    },
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function Header({ textSearch, setTextSearch, setOpenSearch }) {
  const [session] = useSession();
  const router = useRouter();
  const [showInviteUser, setShowInviteUser] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);

  const handleChange = useCallback(
    (event: React.FormEvent) => {
      const input: any = event.target;
      setTextSearch(input.value);
    },
    [setTextSearch],
  );

  const handleOnClick = useCallback(() => {
    Analytics.track(EventTypes.navBarSearchClicked);
    setOpenSearch(true);
  }, []);

  const isCurrent = useCallback(
    (href: string, children: RegExp[] = []) => {
      return (
        href === router.asPath ||
        children.some((reg) => reg.test(router.asPath))
      );
    },
    [router],
  );

  return (
    <Disclosure as="nav" className="bg-white border-b border-gray-200">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <Link href="/">
                  <a className="flex-shrink-0 flex items-center">
                    <Image
                      width="143"
                      height="32"
                      className="block h-8 w-auto"
                      src="/images/logo/full-sm.svg"
                      alt="Workflow"
                    />
                  </a>
                </Link>
                <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                  {navigation.map((item) => (
                    <Link key={item.name} href={item.href}>
                      <a
                        className={classNames(
                          isCurrent(item.href, item.children)
                            ? 'border-indigo-500 text-gray-900'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                          'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium',
                        )}
                        aria-current={
                          isCurrent(item.href, item.children)
                            ? 'page'
                            : undefined
                        }
                      >
                        {item.name}
                      </a>
                    </Link>
                  ))}
                </div>
              </div>
              <div className="hidden md:ml-6 md:flex md:items-center">
                <div className="flex space-x-2">
                  <SearchInput
                    value={textSearch}
                    onChange={handleChange}
                    onClick={handleOnClick}
                    placeholder="Search notes"
                    className="flex-0"
                  />
                  <Button
                    buttonType="link"
                    leadingIcon={
                      <DocumentAddIcon
                        className="block h-4 w-4 mr-2 text-gray-500"
                        aria-hidden="true"
                      />
                    }
                    type="tertiary"
                    className="hidden lg:inline-flex focus:outline-none mr-2"
                    href="/meetings/new"
                    onClick={() =>
                      Analytics.track(EventTypes.navBarNewMeetingClicked)
                    }
                  >
                    New note
                  </Button>
                  <Button
                    leadingIcon={
                      <GiftIcon
                        className="block h-4 w-4 mr-2 text-indigo-500"
                        aria-hidden="true"
                      />
                    }
                    className="hidden lg:inline-flex focus:outline-none mr-2 bg-transparent text-gray-900 hover:text-indigo-500 pl-0"
                    onClick={() => setShowInviteUser(!showInviteUser)}
                    reference={setReferenceElement}
                  >
                    Invite
                  </Button>
                  <InviteUser
                    referenceElement={referenceElement}
                    popoverShow={showInviteUser}
                    setPopoverShow={setShowInviteUser}
                  />
                </div>
                {/* <button className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button> */}

                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative">
                  {({ open }) => (
                    <>
                      <div>
                        <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                          <span className="sr-only">Open user menu</span>
                          {!!session?.user.image && (
                            <img
                              className="h-8 w-8 rounded-full"
                              src={session?.user.image}
                              alt=""
                            />
                          )}
                          {!session?.user.image && (
                            <Avatar
                              size={32}
                              name={session?.user.email}
                              variant="sunset"
                              colors={[
                                '#14B8A6',
                                '#DB2777',
                                '#6c69fd',
                                '#F59E0B',
                              ]}
                            />
                          )}
                        </Menu.Button>
                      </div>
                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          static
                          className="z-10 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <Link href={item.href}>
                                  <a
                                    className={classNames(
                                      active ? 'bg-gray-100' : '',
                                      'block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100',
                                    )}
                                    onClick={item.onClick}
                                  >
                                    {item.name}
                                  </a>
                                </Link>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>
              <div className="-mr-2 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className={classNames(
                    isCurrent(item.href, item.children)
                      ? 'bg-indigo-50 border-indigo-500 text-indigo-700'
                      : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800',
                    'block pl-3 pr-4 py-2 border-l-4 text-base font-medium',
                  )}
                  aria-current={
                    isCurrent(item.href, item.children) ? 'page' : undefined
                  }
                >
                  {item.name}
                </a>
              ))}
            </div>
            <div className="pt-4 pb-3 border-t border-gray-200">
              <div className="flex items-center px-4">
                <div className="flex-shrink-0">
                  {!!session?.user.image && (
                    <img
                      className="h-10 w-10 rounded-full"
                      src={session?.user.image}
                      alt=""
                    />
                  )}
                  {!session?.user.image && (
                    <Avatar
                      size={32}
                      name={session?.user.email}
                      variant="marble"
                      colors={['#14B8A6', '#DB2777', '#6c69fd', '#F59E0B']}
                    />
                  )}
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-gray-800">
                    {session?.user?.name}
                  </div>
                  <div className="text-sm font-medium text-gray-500">
                    {session?.user?.email}
                  </div>
                </div>
                <button className="ml-auto bg-white flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-3 space-y-1">
                {userNavigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
