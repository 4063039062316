import React from 'react';
import { FeedbackFish } from '@feedback-fish/react';

export default function Footer() {
  return (
    <footer className="bg-white mt-4">
      <div className="max-w-7xl mx-auto py-4 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav
          className="-mx-2 -my-2 flex flex-wrap justify-center"
          aria-label="Footer"
        >
          <div className="px-3 py-2">
            <a
              href="https://dealnotes.io"
              className="text-xs text-gray-500 hover:text-gray-900"
            >
              About
            </a>
          </div>
          <div className="px-3 py-2">
            <a
              href="mailto:hello@dealnotes.io"
              className="text-xs text-gray-500 hover:text-gray-900"
            >
              Get in touch with the team
            </a>
          </div>
          {/* <div className="px-3 py-2">
            <FeedbackFish projectId="00701652bbaeab">
              <a href="" className="text-xs text-gray-500 hover:text-gray-900">
                Report a bug or suggest a feature
              </a>
            </FeedbackFish>
          </div> */}
        </nav>
      </div>
    </footer>
  );
}
