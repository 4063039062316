import React from 'react';
import { SearchIcon } from '@heroicons/react/outline';
import { classNames } from 'app/utils/classNames';

interface SearchInputProps {
  value: string;
  onChange;
  onClick?;
  placeholder: string;
  disabled?: boolean;
  className: string;
}

const SearchInput = ({
  value,
  onChange,
  onClick,
  placeholder,
  disabled = false,
  className,
}: SearchInputProps) => {
  return (
    <div
      className={classNames('w-80 max-w-sm text-gray-400', className)}
      onClick={onClick}
    >
      <div className="flex max-w-sm items-center rounded-md border shadow-sm px-3 focus-within:border-indigo-500">
        <SearchIcon className="block h-5 w-5" />
        <input
          type="text"
          value={value}
          disabled={disabled}
          onChange={onChange}
          placeholder={placeholder}
          maxLength={200}
          className="block w-full appearance-none bg-transparent border-none focus:outline-none focus:ring-0 py-2 px-1 text-sm font-light"
        />
      </div>
    </div>
  );
};

export default SearchInput;
