import React, { Dispatch } from 'react';
import Link from 'next/link';

import { EventTypes } from 'app/utils/analytics';

interface ButtonProps {
  buttonType?: 'button' | 'link';
  type?: 'primary' | 'secondary' | 'tertiary' | 'medium' | 'link' | 'default';
  size?: 'xs' | 'sm' | 'md' | 'xl' | '2xl';
  href?: string;
  leadingIcon?: JSX.Element;
  trailingIcon?: JSX.Element;
  buttonOptions?: JSX.Element;
  className?: string;
  children: string;
  blank?: boolean;
  onClick?: () => void;
  trackClickEventType?: EventTypes;
  disabled?: boolean;
  dataTestId?: string;
  reference?: Dispatch<any>;
}

const Button = (props: ButtonProps) => {
  const {
    buttonType = 'button',
    type = 'default',
    size = 'md',
    href = '#',
    leadingIcon,
    trailingIcon,
    buttonOptions,
    className = '',
    children,
    blank,
    onClick = () => null,
    disabled = false,
    dataTestId,
    reference,
  } = props;

  let classNames = [
    'text-center',
    'items-center',
    'border',
    'rounded',
    'font-normal',
    'focus:outline-none',
    'focus:ring-2',
    'focus:ring-offset-2 ',
    'hover-within:bg-gray-800',
    'flex',
    'transition duration-300 ease-out',
    className,
  ];

  switch (size) {
    case 'xs':
      classNames.push('px-2.5', 'py-1.5', 'text-xs');
      break;
    case 'sm':
      classNames.push('px-3', 'py-2', 'text-sm', 'font-light');
      break;
    case 'xl':
      classNames.push('px-4', 'py-2', 'text-base');
      break;
    case '2xl':
      classNames.push('px-6', 'py-3', 'text-base');
      break;
    case 'md':
    default:
      classNames.push('px-4', 'py-2', 'text-sm');
      break;
  }

  if (disabled) {
    classNames.push('text-black', 'bg-gray-200', 'cursor-not-allowed');
  } else {
    switch (type) {
      case 'primary':
        classNames.push(
          'border-transparent',
          'text-white',
          'bg-indigo-600',
          'hover:bg-indigo-700',
          'focus:ring-indigo-500',
        );
        break;
      case 'secondary':
        classNames.push(
          'border-transparent',
          'text-indigo-700',
          'bg-indigo-100',
          'hover:bg-indigo-200',
          'focus:ring-indigo-500',
        );
        break;
      case 'tertiary':
        classNames.push(
          'border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:ring-indigo-500 shadow-sm',
        );
        break;
      case 'medium':
        classNames.push(
          'border-gray-300 text-gray-500 bg-gray-100 hover:bg-gray-150 focus:ring-indigo-500 shadow-sm',
        );
        break;
      case 'link':
        classNames.push(
          'text-indigo-500 hover:bg-gray-50 focus:ring-indigo-500 font-light',
        );
        break;
      default:
        classNames.push('border-transparent', 'focus:ring-transparent');
        break;
    }
  }

  return (
    <div className="relative">
      {buttonType === 'button' && (
        <button
          onClick={onClick}
          className={classNames.join(' ')}
          disabled={disabled}
          data-testid={dataTestId}
          ref={reference}
        >
          <div className="flex justify-center items-center w-full">
            {leadingIcon}
            {children}
            {trailingIcon}
          </div>
        </button>
      )}

      {buttonType === 'link' && (
        <Link href={disabled ? '#' : href} data-testid={dataTestId}>
          <a
            className={classNames.join(' ')}
            target={blank ? '_blank' : ''}
            onClick={onClick}
            ref={reference}
          >
            <div className="flex justify-center items-center w-full">
              {leadingIcon}
              {children}
              {trailingIcon}
            </div>
          </a>
        </Link>
      )}
      {!!buttonOptions && <div>{buttonOptions}</div>}
    </div>
  );
};

export default Button;
