import React from 'react';
import { connectStateResults } from 'react-instantsearch-dom';
import SearchItem from './SearchItem';

function Hits({ searchResults, handleClose }) {
  return (
    <div className="divide-y divide-gray-50">
      {!searchResults?.hits.length && <div className="">no results</div>}
      {!!searchResults?.hits.length &&
        searchResults.hits
          .slice(0, 30)
          .map((hit) => (
            <SearchItem
              key={hit.objectID}
              hit={hit}
              handleClose={handleClose}
            />
          ))}
    </div>
  );
}

export default connectStateResults(Hits);
