import React from 'react';
import { toast } from 'react-toastify';
import {
  CheckCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
  ExclamationIcon,
  QuestionMarkCircleIcon,
  XIcon,
} from '@heroicons/react/solid';
import * as Sentry from '@sentry/browser';
import { classNames } from './classNames';

type ToastType = 'success' | 'info' | 'error' | 'warning';

interface ToastProps {
  type: ToastType;
  message: string;
  error?: Error;
}

const CloseButton = ({ closeToast }) => (
  <button
    className="rounded-md close p-1 focus:outline-none"
    onClick={closeToast}
  >
    <XIcon className="block h-5 w-5 icon" aria-hidden="true" />
  </button>
);

const displayIcon = (type: ToastType) => {
  switch (type) {
    case 'success':
      return <CheckCircleIcon className="block h-5 w-5" aria-hidden="true" />;
    case 'info':
      return (
        <InformationCircleIcon className="block h-5 w-5" aria-hidden="true" />
      );
    case 'error':
      return <XCircleIcon className="block h-5 w-5" aria-hidden="true" />;
    case 'warning':
      return <ExclamationIcon className="block h-5 w-5" aria-hidden="true" />;
    default:
      return (
        <QuestionMarkCircleIcon className="block h-5 w-5" aria-hidden="true" />
      );
  }
};

const ToastMessage = ({ type, message, error }: ToastProps) => {
  if (type === 'error') {
    Sentry.captureException(error);
  }
  return toast[type](
    <div className="flex" data-testid="toast">
      <div className="pt-0 flex-shrink-0 text-center icon">
        {displayIcon(type)}
      </div>
      <div className={classNames('flex-grow px-3 break-word text-sm')}>
        {message}
      </div>
    </div>,
    {
      closeButton: CloseButton,
      pauseOnHover: true,
    },
  );
};

ToastMessage.dismiss = toast.dismiss;

export default ToastMessage;
