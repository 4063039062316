import { formatRelative, parseISO } from 'date-fns';
import { enUS } from 'date-fns/locale';

const formatRelativeLocale = {
  lastWeek: 'MMM d',
  yesterday: "'Yesterday'",
  today: "'Today'",
  tomorrow: "'Tomorrow'",
  nextWeek: 'EEEE',
  other: 'MMM d',
};

const locale = {
  ...enUS,
  formatRelative: (token) => formatRelativeLocale[token],
};

export const friendlyDateFormat = (date: string | Date) => {
  const parseDate = new Date(date);

  return formatRelative(new Date(parseDate.toUTCString()), new Date(), {
    locale,
  });
};

const stringToFormatDate = (date: string | Date = new Date()) => {
  return new Date(date).toLocaleString('en-US', {
    weekday: 'short', // long, short, narrow
    day: 'numeric', // numeric, 2-digit
    // year: 'numeric', // numeric, 2-digit
    month: 'long', // numeric, 2-digit, long, short, narrow
    hour: 'numeric', // numeric, 2-digit
    minute: 'numeric', // numeric, 2-digit
    // second: 'numeric', // numeric, 2-digit
  });
};

export const stringToShortDate = (date: string | Date = new Date()) =>
  new Date(date).toLocaleString('en-US', {
    day: 'numeric', // numeric, 2-digit
    year: 'numeric', // numeric, 2-digit
    month: 'long', // numeric, 2-digit, long, short, narrow
  });

export const stringToExtraShortDate = (date: string | Date = new Date()) =>
  new Date(date).toLocaleString('en-US', {
    day: 'numeric', // numeric, 2-digit
    month: 'short', // numeric, 2-digit, long, short, narrow
  });

export const stringToLocalTime = (date: string | Date = new Date()) =>
  new Date(
    new Date(date).getTime() - new Date().getTimezoneOffset() * 60000,
  ).toISOString();

export default stringToFormatDate;
