import React, { useCallback, useEffect } from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import { XIcon } from '@heroicons/react/solid';
import _ from 'lodash';
import ToastMessage from 'app/utils/toast';

const SearchBox = ({
  refine,
  setTextSearch,
  textSearch,
  handleClose,
  searchCount,
  setSearchCount,
  remainingSearches,
  setRemainingSearches,
}) => {
  const handleOnChange = useCallback(
    (e) => {
      setTextSearch(e.currentTarget.value);
    },
    [setTextSearch],
  );

  const debouncedSearch = useCallback(
    _.debounce((textSearch) => {
      if (remainingSearches > 0) {
        refine(textSearch);
        setSearchCount(searchCount + 1);
        setRemainingSearches(remainingSearches - 1);
      } else {
        ToastMessage({
          type: 'warning',
          message: 'There are no more searches available.',
        });
      }
    }, 500),
    [refine, searchCount, setSearchCount, remainingSearches],
  );

  useEffect(() => {
    debouncedSearch(textSearch);
  }, [textSearch]);

  return (
    <div className="text-gray-700 w-full">
      <div className="flex items-center">
        <input
          type="text"
          value={textSearch}
          onChange={handleOnChange}
          placeholder="Search in notes"
          autoFocus={true}
          maxLength={100}
          className="block w-full appearance-none bg-transparent border-none focus:outline-none focus:ring-0 placeholder-gray-300::placeholder py-2 px-3 text-base mb-1"
        />
        <button
          type="button"
          onClick={handleClose}
          className="inline-flex items-center p-1 border border-transparent rounded-full text-indigo-500 bg-transparent hover:bg-indigo-100 hover:text-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <XIcon className="block h-5 w-5" />
        </button>
      </div>
    </div>
  );
};

export default connectSearchBox(SearchBox);
