import { gql } from '@apollo/client';

export const CREATE_MEETING = gql`
  mutation CreateMeeting($prospectId: Float!, $data: MeetingInput!) {
    createMeeting(prospectId: $prospectId, data: $data) {
      slug
    }
  }
`;

export const UPDATE_MEETING = gql`
  mutation UpdateMeeting($slug: String!, $data: MeetingInput!) {
    update(slug: $slug, data: $data) {
      id
    }
  }
`;
