import React, { useMemo } from 'react';
import getConfig from 'next/config';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch-dom';
import SearchBox from '../elements/SearchBox';
import Hits from '../elements/Hits';
import { useSession } from 'next-auth/client';

const { publicRuntimeConfig } = getConfig();

const Search = ({
  textSearch,
  setTextSearch,
  handleClose,
  searchCount,
  setSearchCount,
  remainingSearches,
  setRemainingSearches,
}) => {
  const [session] = useSession();
  const searchClient = useMemo(
    () =>
      !!session?.searchApiKey &&
      algoliasearch(
        publicRuntimeConfig.algoliaAppId,
        session.searchApiKey as string,
      ),
    [session],
  );
  return (
    <div className="px-3">
      {!!searchClient && (
        <InstantSearch searchClient={searchClient} indexName="meetings">
          <SearchBox
            textSearch={textSearch}
            setTextSearch={setTextSearch}
            handleClose={handleClose}
            searchCount={searchCount}
            setSearchCount={setSearchCount}
            remainingSearches={remainingSearches}
            setRemainingSearches={setRemainingSearches}
          />
          <Hits handleClose={handleClose} />
        </InstantSearch>
      )}
    </div>
  );
};

export default Search;

export const getInitialProps = () => {};
