import { gql } from '@apollo/client';

export const UPDATE_USER = gql`
  mutation updateUser($data: UpdateUserInput!) {
    updateUser(data: $data) {
      id
      email
      name
      dailyDigest
      actionItems
      productUpdates
    }
  }
`;

export const INVITE_USER = gql`
  mutation updateUser($data: InviteUserInput!) {
    inviteUser(data: $data)
  }
`;
